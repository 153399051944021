require("@rails/ujs").start()
require("channels")
require("components/maps")


import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite


const FADEINTIME = 1;
document.addEventListener('DOMContentLoaded', ()=> {
	  gsap.to(['body','html'], FADEINTIME, {opacity: 1, ease: Power4.easeInOut} )

    watchForHover()
    isSafari(); 
})

function isSafari() {
	  var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
	  if (isSafari) { 
	      document.querySelector('body').classList.add('is-safari')
	  };
}

function watchForHover() {
    // lastTouchTime is used for ignoring emulated mousemove events
    let lastTouchTime = 0

    function enableHover() {
        if (new Date() - lastTouchTime < 500) return
        document.body.classList.add('hasHover')
    }

    function disableHover() {
        document.body.classList.remove('hasHover')
    }

    function updateLastTouchTime() {
        lastTouchTime = new Date()
    }

    document.addEventListener('touchstart', updateLastTouchTime, true)
    document.addEventListener('touchstart', disableHover, true)
    document.addEventListener('mousemove', enableHover, true)

    enableHover()
}

////// MOBILE STUFF //////
document.addEventListener('DOMContentLoaded',  () => {
    let hamburger = document.querySelector('.hamburger')
    let headerNav = document.querySelector('.header_nav')
    let headerPre = document.querySelector('.header_pre')
    let navItems = document.querySelectorAll('.header_nav_item')
    let headerPreItems = document.querySelector('.header_pre a')
    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle('is-active')
        headerNav.classList.toggle('is-active')
        headerPre.classList.toggle('is-active')
        document.querySelector('body, html').classList.toggle('no-scroll')
        document.querySelectorAll('.js-open-sub').forEach(i => {
            i.classList.remove('is-active')
        })

        gsap.fromTo([headerPreItems ,navItems], {y: 10, opacity: 0}, {duration: 0.2, y: 0, opacity: 1,  stagger: 0.08});
    })

    document.querySelectorAll('.js-open-sub').forEach(i => {
        i.addEventListener('click', () => {
            document.querySelectorAll('.js-open-sub').forEach( ii => {
                ii.classList.remove('is-active')
            })
            i.classList.add('is-active')
        })
    })
    

})