import Style from './maps_styles.js'

const loadGoogleMapsApi = require('load-google-maps-api');

const iconSizes = {
    '#FFD95E': 21,
    '#0444cc': 18
}
class Map {
  
    static loadGoogleMapsApi() {
        // return loadGoogleMapsApi({ key: 'AIzaSyBklmAAQHOk3ldb39I29zLK-mhwYlwWHQM' }); //SIF API
        return loadGoogleMapsApi({ key: 'AIzaSyCckaPTZ8PZaSsSkVVE3mAZQfwG2_4inTQ' }); //SIF dev open API
    }
    static createMap(googleMaps, mapElement) {
        var italy = {lat: 42.0633500, lng: 12.6432900};
        return new googleMaps.Map(mapElement, {
            center: {lat: 41.0633500, lng: 12.6432900},
            zoom: 5.7, 
            styles: Style.style, 
            disableDefaultUI: true, 
            zoomControl: true
        });
    } 
    static putMarkers(googleMaps, mapObject, info_json, svgs){

        const info = JSON.parse(info_json)
        let markers = []
        // let mapBounds = new googleMaps.LatLngBounds();
        

        info.forEach(currentInfo => {
            let position = {
                lat: parseFloat(currentInfo.lat), 
                lng: parseFloat(currentInfo.lng)
            }
            let c = {
                'yellow': '#FFD95E',
                'blue': '#0444cc'
            }
            let color = c[currentInfo.color]
            currentInfo.color = color

            let svgs = Map.setSvgs(color)
            let icon = { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgs['svg']), scaledSize: new googleMaps.Size(iconSizes[color],iconSizes[color]) }
            let iconActive = { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgs['svgActive']), scaledSize: new googleMaps.Size(iconSizes[color],iconSizes[color]) }
            
            var marker = new googleMaps.Marker({
                map: mapObject,
                position: position, 
                customInfo: currentInfo,
                icon: icon,
                optimized: false
            });
            markers.push(marker)
            // mapBounds.extend(marker.position);
            // googleMaps.fitBounds(mapBounds);
        })

        return markers
        // markers.forEach( i => {

        //     i.addListener('click', () => {
        //         markers.forEach(ii => {
        //             ii.setIcon(icon)
        //         } )
        //         i.setIcon(iconActive)
        //     })
        // })
    }
    static handleMarkers(allMarkers, googleMaps){
        

        
        allMarkers.forEach(marker => {
            
            marker.addListener('click',  ()=> {
                allMarkers.forEach( i => {
                    let color = i.customInfo['color']
                    let svgs = Map.setSvgs(color)

                    let icon = { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgs['svg']), scaledSize: new googleMaps.Size(iconSizes[color],iconSizes[color]) }
                    // let iconActive = { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgs['svgActive']), scaledSize: new googleMaps.Size(iconSizes[color],iconSizes[color]) }
                    i.setIcon(icon)
                })
                let color = marker.customInfo['color']
                let svgs = Map.setSvgs(color)

                // let icon = { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgs['svg']), scaledSize: new googleMaps.Size(iconSizes[color],iconSizes[color]) }
                let iconActive = { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgs['svgActive']), scaledSize: new googleMaps.Size(iconSizes[color],iconSizes[color]) }
                marker.setIcon(iconActive)

                Map.setInfoDiv(marker)
            })
        })
    }

    static setInfoDiv(marker){
        let title = document.querySelector('[info-title]')
        let address = document.querySelector('[info-address]')
        let link = document.querySelector('[info-link]')
        let infoDiv = document.querySelector('.js-info-div')
        infoDiv.classList.remove('is-active') // for fadein
        infoDiv.scrollBy(0, 0) // hack for fadein
        title.innerHTML = marker.customInfo.title
        address.innerHTML = '<span class="uppercase txt-5">indirizzo:&nbsp;</span>' + marker.customInfo.address
        link.innerHTML = `<span class="txt-5 uppercase font-regular">VAI AL &nbsp; </span><a class="u-blue" href="${marker.customInfo.link}">${marker.customInfo.link_title}</a>`
        infoDiv.classList.add('is-active') // for fadein
    }

    /// NON USATA AL MOMENTO PERCHE LO ZOOM DIPENDE TROPPO DALLA VIEWPORT
    static fitBounds(allMarkers, mapObject, bounds){
        
        allMarkers.forEach(marker => {
            bounds.extend(marker.getPosition())
        })
        mapObject.fitBounds(bounds)
        
    }

    static setSvgs(color){
        let svgs = {
            svg: [
                `<?xml version="1.0"?>`,
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.3 208.3">`,
                        `<circle fill="${color}"  stroke-width="15px" stroke="white" cx="106.15" cy="106.15" r="100.65"/>`,
                    `</svg>`
                ].join('\n'),
            svgActive: [
                `<?xml version="1.0"?>`,
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.3 208.3">`,
                        `<circle fill="#fff" stroke="${color}" stroke-width="15px" cx="104.15" cy="104.15" r="100.65"/>`,
                        `<circle fill="${color}" cx="104.15" cy="104.15" r="71.93"/>`,
                    `</svg>`
            ].join('\n')
        }
        return svgs
    }
}



document.addEventListener('DOMContentLoaded', () => {
    const mapDiv = document.querySelector('#map')
    if(mapDiv){
        Map.loadGoogleMapsApi()
            .then((googleMaps) => {
                let mapObject = Map.createMap(googleMaps, mapDiv)
                let info_json = mapDiv.getAttribute('data-info')
                let allMarkers = Map.putMarkers(googleMaps, mapObject, info_json)
                Map.handleMarkers(allMarkers, googleMaps )
                /// NON USATA AL MOMENTO PERCHE LO ZOOM DIPENDE TROPPO DALLA VIEWPORT
                // let bounds = new googleMaps.LatLngBounds()
                // Map.fitBounds(allMarkers, mapObject, bounds)
                // Map.handleMarkers(allMarkers, svgs, googleMaps)
                
                
                
            })
    }
})